"use client";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../../lib/utils";
import { throttle } from "lodash";

export const WobbleCard: React.FC<{
  children: React.ReactNode;
  containerClassName?: string;
  className?: string;
}> = ({ children, containerClassName, className }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
  const { clientX, clientY } = event;
  const rect = event.currentTarget.getBoundingClientRect();
  const x = (clientX - (rect.left + rect.width / 2)) / 20;
  const y = (clientY - (rect.top + rect.height / 2)) / 20;
  setMousePosition({ x, y });
}

  return (
    <motion.section
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false);
        setMousePosition({ x: 0, y: 0 });
      }}
      // Note:kg 2024-06-07_12-58-AM: something in here causing the black bar to appear across the screen
      // style={{
      //   transform: isHovering
      //     ? `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0) scale3d(1, 1, 1)`
      //     : "translate3d(0px, 0px, 0) scale3d(1, 1, 1)",
      //   transition: "transform 0.9s ease-out",
      // }}
      className={cn(
        "mx-auto w-8/12 bg-greyGR border border-blueGR relative rounded-2xl overflow-hidden",
        containerClassName
      )}
    >
      <div
        className="relative h-full sm:mx-0 sm:rounded-2xl overflow-hidden"
        style={{
          boxShadow:
            "0 10px 32px rgba(34, 42, 53, 0.12), 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(34, 42, 53, 0.05), 0 4px 6px rgba(34, 42, 53, 0.08), 0 24px 108px rgba(47, 48, 55, 0.10)",
        }}
      >
        <motion.div
          style={{
            transform: isHovering
              ? `translate3d(${-mousePosition.x}px, ${-mousePosition.y}px, 0) scale3d(1.03, 1.03, 1)`
              : "translate3d(0px, 0px, 0) scale3d(1, 1, 1)",
            transition: "transform 0.1s ease-out",
          }}
          className={cn("h-full px-4 py-3.5 sm:px-10", className)}
        >
          <Noise />
          {children}
        </motion.div>
      </div>
    </motion.section>
  );
};

const Noise = () => {
  return (
    <div
      className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"
      style={{
        backgroundImage: "../../assets/images/noise.webp",
        backgroundSize: "100%",
      }}
    ></div>
  );
};

export default WobbleCard;
